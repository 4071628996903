.account-section__content {
  .address-controls a {
    text-decoration: underline;
  }
}

.address-book-page__overlay {
  #cboxClose {
    right: 20px;
    top: 20px;
  }
  .form-item {
    &.qas-submit-container,
    &.qas_submit_container {
      input[type='button'] {
        background: #392720 !important;
      }
    }
    &.title {
      span.label-content {
        display: none;
      }
    }
    select {
      width: 100%;
    }
  }
  #qas {
    .error {
      color: red;
    }
    .manual-address {
      margin-top: 10px;
    }
  }
  #manual-address-fields {
    margin-top: 15px;
  }
  .js-select-box-label {
    margin-bottom: 1.5em;
    width: 100%;
  }
}

.product-full {
  .product-sku-price__value {
    display: inline;
    clear: both;
    text-align: left;
  }
  .sku-menu__container {
    .select-box__options.open {
      z-index: 99;
    }
  }
  .product-sku-unit-price__value {
    float: left;
    width: 78%;
  }
  .product-price-points-display {
    text-align: left;
  }
  .product-points {
    display: block;
    width: 100%;
    text-align: left;
  }
}

.product-quickshop__details {
  .product-sku-unit-price__value {
    float: left;
    width: 78%;
  }
  .product-price-points-display {
    text-align: left;
  }
}

.profile-page__content {
  border-bottom: 1px solid $color-light-gray;
  padding-bottom: 20px;
  input[type='checkbox'] ~ label {
    margin: 0 0 10px 10px;
    float: left;
    width: 91%;
  }
}

body.toolbar {
  .site-header__wrapper {
    top: 0;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .product-full__quote {
    width: 30%;
  }
  .account-section__content {
    padding-right: 15px;
  }
}

.past-purchases__content {
  .tracking-link {
    .tracking_disclaimer {
      display: none;
    }
  }
}

.profile-page__content,
.new-account__fieldset {
  .newsletter-info__fieldset {
    .profile-page & {
      width: 100%;
      margin-#{$rdirection}: 0;
    }
  }
  #providing-email {
    text-align: justify;
    width: 90%;
  }
  .sms-promotions {
    width: 100%;
    margin-top: 10px;
    .sms-info__prefix {
      margin: 20px 0 0 25px;
      span {
        border: 1px solid;
        padding: 0 10px 0 10px;
        display: inline-block;
        line-height: 2.45em;
      }
    }
    .sms-info__number-field {
      width: 45%;
      position: absolute;
      margin: -36px 0 0 90px;
      input[type='tel'] {
        height: 36px;
      }
    }
    .sms-mobile-terms {
      margin: 20px 0 20px;
      .mobile-terms {
        text-transform: inherit;
        font-weight: normal;
        text-align: justify;
        margin-bottom: 10px;
      }
    }
  }
  .pure-privilege-info {
    .pp-link {
      &__email-field,
      &__smsoptin-field {
        margin-bottom: 20px;
      }
    }
  }
}

#manual-address-fields {
  .country_container {
    #form--address_billing--field--COUNTRY_ID {
      .js-select-box-options {
        div:first-child {
          display: none;
        }
      }
    }
  }
}

.giftcard-checkbalance-page {
  ul.error {
    margin-bottom: 15px;
    color: red;
  }
}

.payment-delete-confirm {
  text-align: center;
}

.waitlist-iframe-wrapper {
  width: 100%;
}

#waitlist {
  .email_input,
  .align-r {
    float: left;
    display: inline-block;
    margin-right: 10px;
  }
}

.signin-block {
  &__required {
    margin-bottom: 15px;
  }
}

.password-request-page,
.password-reset-page,
.password-sent-page {
  .email-address {
    font-weight: bold;
  }
  .sent-info__text {
    .button {
      background: $color-off-black;
      color: $white;
      text-decoration: none;
    }
  }
}

.waitlist-form {
  padding-top: 35px;
  .waitlist-tooltip {
    margin-top: 15px;
    .tooltiptext-over {
      max-width: 100%;
    }
  }
}
