.service-price-page-block {
  .service-price-block {
    .mobile-dropdown-list {
      a.tabbed-block__tabs-item {
        display: none;
      }
      select.dropdown-menu-list {
        width: 100%;
      }
      .dropdown-menu-list option {
        text-align: center;
      }
    }
    .tabbed-block__text {
      padding: 0 10%;
    }
  }
  .tabbed-block__sub_title {
    padding: 10px 0;
  }
  .service-content {
    width: 90%;
    margin: 0 auto;
  }
  .services-content-block-left {
    text-align: justify;
    margin-bottom: 20px;
    .services-content-block__text p {
      line-height: 22px;
    }
    .services-content-block__image {
      padding: 20px 0;
    }
  }
  .services-content-block-right {
    text-align: justify;
    margin-bottom: 20px;
    .services-content-block__text {
      .label-content {
        width: 50%;
        float: left;
        margin-bottom: 15px;
      }
      .label-price {
        text-align: right;
        margin-bottom: 15px;
      }
    }
    .service-content-block-split__text p {
      text-transform: initial;
      padding: 10px 0;
      line-height: 22px;
    }
    .service-content-block-split__cta a {
      padding: 10px 0;
      text-transform: initial;
    }
  }
  .service-book-split {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 0;
    &__cta a {
      width: 100%;
    }
    &__text {
      padding: 10px;
      text-align: center;
    }
  }
}

.institute-menu-bottom {
  .title-menu-bottom {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 20px;
  }
  .img-block-split__image {
    float: left;
    width: 46%;
    text-align: center;
    margin: 2%;
  }
  .link-block-split__text {
    margin: 10px;
  }
}

.services {
  &-hair {
    padding: 20px 15px 0;
  }
  &-table {
    margin-bottom: 0;
    td {
      padding: 5px;
      p {
        margin-bottom: 0;
      }
    }
    th {
      padding: 5px;
      text-transform: uppercase;
      p {
        margin-bottom: 0;
      }
    }
  }
}

//Joint the Conversation Page Styles
.join-conversation {
  text-align: center;
  padding: 0 18px;
  max-width: 900px;
  margin: 0 auto;
  &-head {
    font-weight: 700;
    font-size: 28px;
    line-height: 1;
    padding-top: 25px;
  }
  &-subtitle {
    font-size: 14px;
    line-height: 20px;
    padding-top: 7px;
    margin-bottom: 0;
  }
  &__social-icons {
    text-align: center;
    padding-bottom: 20px;
    a {
      text-decoration: none;
      span {
        font-size: 24px;
        margin-right: 25px;
      }
      &:last-child {
        span {
          margin-right: 0;
        }
      }
    }
  }
}

.olapic-wall-widget {
  .olapic-wall-header {
    .olapic-top {
      .olapic-tools {
        display: none;
      }
    }
  }
  .olapic-wall-type-video {
    i {
      z-index: 99;
    }
  }
  .olapic-loadmore {
    background: $color-off-black;
    color: $white;
    border-color: $white;
    z-index: 99;
  }
}

.homepage-hero {
  &__nav {
    display: none;
  }
  &__slide {
    &__inner {
      height: 53vw;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  &__inner {
    .slick-dots {
      li {
        display: none;
      }
    }
  }
}
