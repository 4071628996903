.content-container {
  .cs-page {
    &__content-item {
      #contact_us {
        .my_message {
          textarea {
            height: 5em;
          }
        }
        .field-input {
          input[type='text'],
          input[type='tel'] {
            height: 38px;
          }
        }
      }
    }
  }
}

.welcome-15 {
  .site-email-signup {
    &__success {
      padding-top: 1.8em;
    }
    &__success-header {
      line-height: 1.3;
    }
  }
}

.site-footer {
  &__customer {
    .menu {
      &__item--lvl-2 {
        .LPMcontainer {
          line-height: 40px !important;
        }
      }
    }
  }
}

.waitlist-form {
  &__errors {
    color: $color-red;
  }
}

.product-quickshop {
  .product-sku-price {
    text-align: right;
    &__value.unit_price,
    .product-points {
      float: left;
      text-align: left;
    }
    .product-points {
      width: 100%;
    }
  }
  .product-add-to-waitlist {
    background: #392720;
    color: #fbf8f6;
    border-color: #fbf8f6;
  }
}
/* Inventory Visibility */
.spp__container {
  .instore-inventory-container {
    .product-store-check {
      &__inline--container {
        border-top: 0;
        margin-bottom: 5px;
        border-bottom: 1px solid $black;
      }
      .location-controls {
        &__zip {
          margin-top: 10px;
        }
      }
    }
    .find-in-store {
      border: 1px solid $color-off-black;
      width: 100%;
      padding: 5px 0;
      display: inline-block;
      text-align: center;
      font-size: 16px;
      margin: 10px 0;
      cursor: pointer;
    }
  }
}
