/* Tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  width: 100%;
  &.photo-tip {
    padding-top: 5px;
  }
  a {
    text-decoration: none;
  }
  .tooltiptext-under,
  .tooltiptext-over {
    visibility: hidden;
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    color: #555;
    border: 1px solid #999999;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 3;
    $ldirection: 0;
    opacity: 0;
    transition: opacity 0.3s;
    left: 0;
  }
  .tooltiptext-under {
    top: 125%;
  }
  .tooltiptext-over {
    bottom: 125%;
  }
  .tooltiptext-under {
    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      $ldirection: 17%;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #555 transparent;
    }
  }
  .tooltiptext-over {
    &::before {
      content: '';
      position: absolute;
      top: 100%;
      $ldirection: 17%;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }
  }
  &:hover {
    .tooltiptext-under,
    .tooltiptext-over {
      visibility: visible;
      opacity: 1;
    }
  }
}

.privacy_link_under {
  text-align: $rdirection;
  padding: 1em;
  text-decoration: underline;
}
/* Institute Menu */
.mobile-image-nav {
  .basic-image {
    &__img {
      margin: 15px 0;
    }
  }
}
/* Product Full Sale Price */
.has-sale {
  .product-sku-unit-price__value {
    display: block;
    width: 100%;
  }
  .product-sku-price__value_red {
    color: $red;
    position: relative;
  }
}

.product-quickshop__details {
  .has-sale {
    .product-sku-price2__value {
      margin-left: -12%;
    }
  }
}
/* Product Full Sale Price */

/* Ipad Gnav portrait mode */
@include breakpoint(($portrait-up) ($medium-down) (orientation portrait)) {
  .site-header__menu {
    .site-header__menu-list--desktop {
      .menu--lvl-1 {
        font-size: 14px;
        margin-left: 12px;
        .menu__item--lvl-1 {
          margin-right: 8px;
        }
      }
    }
  }
}

.password-field {
  position: relative;
  &__info {
    @include breakpoint($large-up) {
      border: 1px solid $current-black;
      width: 50%;
      position: absolute;
      left: 105%;
      top: -32px;
    }
    &-reset {
      bottom: auto;
      top: 0;
      @include breakpoint($landscape-up) {
        border: 1px solid $current-black;
        width: 50%;
        position: absolute;
        left: 105%;
        &::before {
          content: '';
          position: absolute;
          top: 43%;
          right: 100%;
          border-width: 5px;
          border-style: solid;
          border-color: transparent $current-black transparent transparent;
        }
        .password-field__rules {
          column-count: 1;
          padding: 10px;
        }
      }
    }
    width: 100%;
    background-color: $white;
    z-index: 99;
    &::before {
      @include breakpoint($large-up) {
        content: '';
        position: absolute;
        top: 43%;
        right: 100%;
        border-width: 5px;
        border-style: solid;
        border-color: transparent $current-black transparent transparent;
      }
    }
  }
  &__rules {
    column-count: 2;
    padding: 0 10px 17px 10px;
    @include breakpoint($portrait-up) {
      .password-reset-page & {
        padding: 10px;
      }
    }
    @include breakpoint($large-up) {
      column-count: 1;
      padding: 10px;
    }
    font-size: 0.8em;
    list-style: none;
    > li {
      display: flex;
      align-items: center;
      padding: 3px 0;
      color: $color-gray;
      transition: 0.2s;
      white-space: nowrap;
      line-height: 1.5;
      &::before {
        content: '\2715';
        display: inline-block;
        color: $color-red;
        font-size: 1em;
        line-height: 0;
        margin: 0 6px 0 0;
        transition: 0.2s;
      }
      &.pass {
        color: $color-green;
        &::before {
          color: $color-green;
          content: '\2713';
          text-shadow: 0 0 8px rgba($current-black, 0.5);
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  #viewcart-panel {
    .cart-items {
      .total {
        .pc_hidden {
          display: block;
        }
      }
    }
  }
}

.product-store-check {
  &__inline--container {
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    margin: 10px 0;
  }
}

.product-full {
  &__action {
    display: block;
    float: $ldirection;
    margin: 5px 16px 0 0;
  }
}
/* Inventory Visibility */
.spp__container {
  @include breakpoint(($medium-only) (orientation portrait)) {
    .instore-inventory-container {
      clear: $ldirection;
    }
  }
}

.footer {
  .site-footer__signup {
    .site-email-signup__form {
      .tooltip {
        width: 100%;
      }
    }
  }
}

.kit-formatter {
  .product-kit__sticky {
    padding: 5px;
    .sticky-add-to-bag__container {
      .sticky-add-to-bag__image-item {
        height: 70px;
      }
    }
  }
}

@include breakpoint($medium-up) {
  .content-block-large {
    &__text {
      .aveda-way-text {
        margin-bottom: 13%;
      }
    }
  }
}

//One Trust Implementation Changes
.optanon-alert-box-wrapper {
  .optanon-alert-box-bg {
    .optanon-alert-box-button-middle {
      .accept-cookies-button,
      .cookie-settings-button {
        &:active,
        &:hover {
          background: none;
        }
      }
    }
  }
}

.cookie-settings-ot {
  .optanon-show-settings-popup-wrapper {
    margin-bottom: 20px;
    height: 35px;
    @include breakpoint($landscape-up) {
      margin-bottom: 0;
    }
  }
}

.profile-info {
  &__password_criteria {
    clear: both;
    padding: 0 0 12px 0;
  }
}

.mpp-container {
  .product {
    &__price-installment {
      display: none;
    }
  }
}

.spp-content {
  .afterpay-paragraph {
    clear: left;
    .Clearpay-logo {
      vertical-align: sub !important; //!important is used to override the inline styles from 3rd party content
    }
  }
}

//Clearpay popup override
#afterpay-modal-overlay {
  #afterpay-modal-modal {
    height: 95%;
    width: 95%;
    @include breakpoint($medium-up) {
      height: 80%;
      width: 60%;
    }
    #afterpay-modal-close {
      min-width: 5%;
    }
  }
}

body#index {
  .pg_wrapper {
    .gift-options {
      .card-message {
        textarea.adpl--processed + label {
          line-height: 1;
        }
      }
    }
  }
}

.klarna-information {
  margin: auto;
  max-width: 660px;
  @include breakpoint($landscape-up) {
    padding: 20px;
  }
  &__header {
    background-image: url('/media/export/images/checkout/icon--klarna.svg');
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 80px;
    margin-bottom: 25px;
    text-indent: -999em;
  }
  &__list {
    margin-bottom: 25px;
    margin-#{$ldirection}: 30px;
  }
  &__list-item {
    align-items: baseline;
    display: flex;
    margin-bottom: 10px;
    &::before {
      content: '.';
      flex-basis: 30px;
      font-size: 24px;
      padding-#{$rdirection}: 10px;
    }
  }
  &__sub-text {
    color: $color-dark-gray;
    margin: 0;
    .klarna-information__link {
      border: 0;
      border-radius: 0;
      font-size: inherit;
      height: auto;
      line-height: inherit;
      padding: 0;
      text-decoration: underline;
      text-transform: none;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}

.klarna-overlay {
  #cboxClose {
    color: transparent;
  }
}
